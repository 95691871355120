@import url('https://fonts.googleapis.com/css2?family=Lexend+Exa:wght@400;700&display=swap');

body {
    font-family: 'Lexend Exa', sans-serif;
}

:root {
    --color-black: #222222;
    --color-white: #eeeeee;
    --color-dark: #394358;
    --color-light: #dcd8ce;
    --color-yellow-light: #F4EFC5;
    --color-yellow: #fdd067;
    --color-pink: #ffacb5;
    --color-purple: #3f00a3;
    --color-top-gradient: var(--color-white);
    --color-primary: var(--color-light);
    --color-secondary: var(--color-dark);
    --video-brighness: 1.5;
  }
.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 20px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: var(--color-secondary) 2px solid;
    border-radius: 34px;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 27px;
    width: 27px;
    left: -5px;
    top: -5px;
    background-color: var(--color-secondary);
    transition: .4s;
    border-radius: 50%;
  }

.slider:hover::before {
    height: 29px;
    width: 29px;
    left: -6px;
    top: -6px;
}

  input:checked + .slider:before {
    transform: translateX(26px);
  }
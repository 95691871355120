/*body,html {*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/
/** {*/
/*    box-sizing: border-box;*/
/*}*/

a {
    color: var(--color-secondary);
}

a:hover {
    text-decoration: none;
}

p {
    margin: 0;
}

::selection {
    background: var(--color-pink)
}

.page {
    width: 100vw;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    background-color: var(--color-primary);
    color: var(--color-secondary);
/*    padding-bottom: 90px;*/
}

.color-switch {
    position: absolute;
    margin: 15px 17px 15px 15px;
    right: 0;
    z-index: 1;
}

.home {
    height: 100vh;
    min-height: 400px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
/*    padding-bottom: 150px;*/
    box-sizing: border-box;
    background: linear-gradient(180deg, var(--color-top-gradient) 0%, var(--color-primary) 100%);
    perspective: 4em;
}

.logo {
    position:absolute;
    mask-image: url('../images/logo.svg');
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center top;
    background-color: var(--color-secondary);
    width: 600px;
    height: 300px;
    max-width: 60vw;
    max-height: 30vw;
    transform-origin: center center;
    transform-style: preserve-3d;
    padding-bottom: 15rem;
}

.details {
    position: absolute;
    margin-top: 30vh;
}

h1.name {
    font-size: 1.5rem;
    text-transform: uppercase;

    margin-bottom: 15px;
    z-index: 1;
}

h2.description {
    font-weight: 400;
    font-size: 0.9rem;
    z-index: 1;
}

video {
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
    z-index: -100;
    filter: brightness(var(--video-brighness));
}

.contact {
    position: fixed;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 100px 30px 25px;
    font-weight: 800;
/*    background: linear-gradient(180deg, rgba(255, 0, 0, 0) 0%, var(--color-primary) 50%)*/
}

.resume {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    max-width: 1000px;
    padding: 50px;
}

h3.cat-title {
    font-weight: 800;
    font-size: 16px;
    text-transform: uppercase;
    margin: 50px 0 20px;
}

.no-col {
    display: block
}

.col {
    display: flex;
    column-gap: 30px;
}

.col div {
    flex-grow: 1
}

.line div {
    flex: 0 0 calc(50% - 30px);
}

@media (min-width: 992px) {
    .contact {
        display: flex;
        justify-content: space-between;
    }
}